<template>
  <b-card>
    <b-table hover :items="services" :fields="fields">
      <template #cell(show)="row">
        <feather-icon icon="PlusCircleIcon" size="20" v-if="!row.detailsShowing" @click="row.toggleDetails" />
        <feather-icon icon="MinusCircleIcon" size="20" v-if="row.detailsShowing" @click="row.toggleDetails" />
      </template>


      <template #row-details="row">
        <b-tabs v-model="row.item.active_tab_index">
          <b-tab title="Content">
            <b-card>
              <b-row style="gap: 20px 0" v-if="row.item.content && row.item.content.length > 0">
                <b-col v-for="(c, index) in row.item.content" :key="'c_' + index" cols="12" md="3">
                  <span>{{ c.content_name }}</span>
                  <br />
                  <div v-if="c.uploaded_file_link">
                    <embed :src="FILESURL + c.uploaded_file_link" width="100%" height="200px" />
                    <br />
                    <span>
                      <a target="_blank" :href="FILESURL + c.uploaded_file_link">open</a>
                    </span>
                  </div>

                  <div v-if="c.file_link">
                    <embed :src="c.file_link" width="100%" height="200px" />
                    <br />
                    <span>
                      <a target="_blank" :href="c.file_link">open</a>
                    </span>
                  </div>

                  <div v-if="c.youtube_link">
                    <div v-html="c.youtube_link"></div>
                  </div>

                  <!-- <div v-if="c.sat_report_link">
                    <embed :src="pdfUrl" width="100%" height="200px" />
                    <br />
                    <span>
                      <a target="_blank" :href="pdfUrl">open</a>
                    </span>
                  </div> -->

                </b-col>
              </b-row>
              <div v-else>No Content Available.</div>
            </b-card>
          </b-tab>
          <b-tab title="Features">
            <div class="shadow-xl rounded-lg border" style="overflow: hidden">
              <div class="d-flex border-bottom bg-white">
                <h4 class="my-1 ml-1 p-0" style="min-width: 500px">Deliverables</h4>
                <h4 class="my-1" style="min-width: 200px">Progress</h4>
                <h4 class="mt-1" style="min-width: 200px">Completion Date</h4>
              </div>
              <div class="" style="background-color: #fafafa; overflow: hidden;">
                <div class="d-flex pt-1 border-bottom pb-1 ml-1" v-for="(feature, index) in row.item.features"
                  :key="'feature_' + index">
                  <span class="font-weight-bold" style="min-width: 500px">{{
                    feature.feature_name
                  }}</span>

                  <div style="min-width: 200px">
                    <b-badge class="ml-1 mr-5" :variant="isFeatureCompleted(feature.feature_id)
                      ? 'success'
                      : 'warning'
                      ">{{
                        isFeatureCompleted(feature.feature_id)
                          ? "Complete"
                          : "Pending"
                      }}</b-badge>
                  </div>
                  <div v-if="getCompletedFeatureDate(feature.feature_id)" class="rounded-lg shadow-sm"
                    style="padding: 2px 8px">
                    {{
                      moment(
                        getCompletedFeatureDate(feature.feature_id)
                      ).format("DD MMM, YYYY HH:mm:ss")
                    }}
                  </div>
                </div>
              </div>
            </div>
          </b-tab>
          <b-tab title="Part Payments" v-if="row.item.partPayments && row.item.partPayments.length > 0">
            <div class="shadow-xl rounded-lg border" style="overflow: hidden">
              <div class="d-flex border-bottom bg-white">
                <h4 class="my-1 ml-1 p-0" style="min-width: 500px">Part</h4>
                <h4 class="my-1" style="min-width: 200px">Price</h4>
                <h4 class="mt-1" style="min-width: 200px">Status</h4>
                <h4 class="mt-1" style="min-width: 200px">Payment Date</h4>
              </div>

              <div class="" style="background-color: #fafafa; overflow: hidden;">
                <div class="d-flex pt-1 border-bottom pb-1 ml-1" v-for="(part, index) in row.item.partPayments"
                  :key="'feature_' + index">
                  <span class="font-weight-bold" style="min-width: 500px">Part {{ index + 1 }}</span>

                  <div style="min-width: 200px">
                    {{ part.spp_price }} {{ part.spp_currency }}
                  </div>
                  <div style="min-width: 200px">

                    <b-badge class="ml-1 mr-5" :variant="part.is_paid === 'Y'
                      ? 'success'
                      : 'warning'
                      ">{{
                        part.is_paid === 'Y'
                          ? "Paid"
                          : "Pending"
                      }}</b-badge>

                    <!-- {{ part.is_paid === 'Y' ? "Paid" : "Not Paid" }} -->
                  </div>
                  <div style="padding: 2px 8px" v-if="part.is_paid === 'Y'">
                    {{
                      moment(
                        part.updated_at
                      ).format("DD MMM, YYYY HH:mm:ss")
                    }}
                  </div>
                  <div v-else-if="index === row.item.partPayments.filter(p => p.is_paid === 'Y').length">
                    <b-badge variant="primary">Pay Now</b-badge>
                  </div>
                  <div v-else>
                    <!-- <b-badge variant="primary">Pay Now</b-badge> -->
                  </div>
                </div>
              </div>
            </div>
          </b-tab>
        </b-tabs>
      </template>

      <template #cell(buy_date)="row">
        {{ moment(row.item.buy_date).format("DD MMM, YYYY - HH:mm:ss") }}
      </template>

      <template #cell(amount)="row">
        {{ row.item.partPayments.length > 0 ?
          `${row.item.partPayments.filter(p => p.is_paid === 'Y').reduce((acc, p) => acc + p.spp_price, 0)}
        ${row.item.partPayments[0].spp_currency || ""}` : row.item.amount }}
      </template>
    </b-table>
  </b-card>
</template>
<script>
import BCardCode from "@core/components/b-card-code";
import {
  BCarousel,
  BCarouselSlide,
  BButtonGroup,
  BButton,
  BLink,
  BCardText,
  BTabs,
  BTab,
  BCard,
  BCardHeader,
  BCardBody,
  BBadge,
  BCollapse,
  BListGroup,
  BListGroupItem,
  BFormCheckbox,
  BCol,
  BRow,
  BTable,
} from "bootstrap-vue";

import Ripple from "vue-ripple-directive";
import StudentServices from "@/apiServices/StudentServices";
import store from "@/store";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import moment from "moment";
import { FILESURL } from "@/config";

export default {
  components: {
    BCardCode,
    BCard,
    BCardHeader,
    BCardBody,
    BCollapse,
    BCarousel,
    BCarouselSlide,
    BButtonGroup,
    BButton,
    BCardText,
    BTabs,
    BLink,
    BTab,
    BListGroup,
    BListGroupItem,
    BFormCheckbox,
    BRow,
    BCol,
    BTable,
    BBadge,
  },
  directives: {
    Ripple,
  },
  props: {
    servicesAvailed: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    user_type() {
      if (store.getters["user/getUserDetails"]?.user_type) {
        return store.getters["user/getUserDetails"].user_type;
      } else {
        return null;
      }
    },
  },

  data() {
    return {
      active_tab_index: 0,
      services: [],
      completed_features: [],
      fields: ["show", "service_name", "plan_name", "amount", "buy_date"],
      FILESURL,
      pdfUrl: null

    };
  },
  methods: {
    async loadPdf(link) {
      console.log('here')
      try {
        const response = await fetch(link);
        const blob = await response.blob();
        this.pdfUrl = URL.createObjectURL(blob); // Creating a Blob URL for PDF
        console.log(this.pdfUrl)
      } catch (error) {
        console.error("Failed to load SAT report:", error);
      }
    },
    convertBinaryToPdfUrl(binaryData) {
      if (binaryData) {
        const blob = new Blob([binaryData], { type: "application/pdf" });
        this.pdfUrl = URL.createObjectURL(blob);
      }
    },
    moment,
    isFeatureCompleted(feature_id) {
      const check = this.completed_features.find(
        (feature) => feature.feature_id == feature_id
      );

      if (check) return true;
      return false;
    },

    getCompletedFeatureDate(feature_id) {
      const feature = this.completed_features.find(
        (feature) => feature.feature_id == feature_id
      );

      if (feature) return feature.created_at;
      return null;
    },

    async getServicesAvailed() {
      try {
        const response = await StudentServices.getServicesAvailed();
        if (response.data.status) {
          this.services = response.data.data.map((s) => ({
            ...s,
            active_tab_index: 0,
            _showDetails: this.servicesAvailed && s.service_name === "SAT" ? true : false,
          }));
        }
        console.log(this.services)
        for (const service of this.services) {
          if (service.content) {
            for (const content of service.content) {
               this.convertBinaryToPdfUrl(content.sat_report_link);
            }
          }
        }
      } catch (err) {
        console.log("Error in getting student availed services ", err);
      }
    },

    beforeMount() {
      this.getServicesAvailed();
     
    },

    async getCompletedFeatures() {
      try {
        const response = await StudentServices.getCompletedFeatures();
        if (response.data.status) {
          this.completed_features = response.data.data;
        }
      } catch (err) {
        console.log("Error in getCompletedFeatures", err);
      }
    },
  },

  beforeMount() {
    this.getServicesAvailed();
    this.getCompletedFeatures();
  },
  beforeDestroy(){
    if (this.pdfUrl) {
      URL.revokeObjectURL(this.pdfUrl);
    }
  }
};
</script>

<style></style>
4